<template>
  <v-card>
    <reactive-base
      :app="elasticSearchCluster"
      :url="url"
      :credentials="credentials"
      type="_doc"
    >
      <v-row>
        <v-col cols="10">
          <v-combobox
            v-model="selectedFields"
            :items="allFields"
            label="Columnas"
            multiple
            chips
          ></v-combobox>
        </v-col>
        <v-col cols="2">
          <v-select
            :items="pageSizeOptions"
            v-model="pageSize"
            label="Resultados"
          ></v-select>
        </v-col>
      </v-row>

      <v-col cols="12">
        <data-search
          :innerClass="{ input: 'reactive-search-input' }"
          :showIcon="false"
          :URLParams="true"
          :showClear="false"
          filterLabel="Name"
          componentId="SearchSensor"
          :autosuggest="true"
          placeholder="Buscar..."
          :dataField="visibleTextFields"
          class=" grow"
        />
      </v-col>

      <ReactiveList
        componentId="result"
        class="results"
        :dataField="dataField"
        :sortBy="sortDirection"
        :innerClass="{ resultStats: 'result-stat' }"
        pagination
        :size="parseInt(pageSize)"
        :URLParams="true"
        :react="reactiveListReact"
        prevLabel="<"
        nextLabel=">"
        :showResultStats="false"
      >
        <div slot="render" slot-scope="{ loading, error, data }">
          <div v-if="loading">Fetching Results.</div>
          <div v-else-if="Boolean(error)">
            Something went wrong! Error details {{ JSON.stringify(error) }}
          </div>
          <v-simple-table v-else>
            <thead>
              <tr>
                <th v-for="h in selectedHeaders" :key="h.value">
                  <span
                    @click="sortAscending = !sortAscending"
                    v-if="h.value == sortField"
                    class="sorting-field"
                    >{{ h.text }}
                    <v-icon
                      >mdi-chevron-{{
                        (sortAscending && "up") || "down"
                      }}</v-icon
                    ></span
                  >
                  <span
                    @click="updateSortingField(h)"
                    class="sortable-field"
                    v-else-if="isSortable(h.display)"
                    >{{ h.text }}</span
                  >

                  <span v-else>{{ h.text }}</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-bind:key="result._id" v-for="result in data">
                <td v-for="h in selectedHeaders" :key="h.value">
                  <div v-if="h.display == 'chip'">
                    <v-chip v-for="val in result[h.value]" :key="val">{{
                      val
                    }}</v-chip>
                  </div>
                  <div v-else-if="h.display == 'boolean'">
                    <v-icon :color="(result[h.value] && 'green') || 'red'">{{
                      `mdi-${(result[h.value] && "check") || "close"}`
                    }}</v-icon>
                  </div>
                  <div v-else>
                    {{ result[h.value] }}
                  </div>
                </td>
                <td>
                  <v-icon
                    @click="
                      $router.push({
                        name: 'KBDrugDetail',
                        params: { drugId: result._id }
                      })
                    "
                    >mdi-circle-edit-outline</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </ReactiveList>
    </reactive-base>
  </v-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      credentials: process.env.VUE_APP_APPBASE_CRED,
      url: process.env.VUE_APP_APPBASE_URL,
      sortField: "Slug",
      sortAscending: true,
      pageSizeOptions: ["10", "25", "50"],
      pageSize: "8",
      selectedFields: ["ID", "Substrato de", "EPG", "PGX"],
      headers: [
        {
          text: "ID",
          value: "Slug",
          display: "text"
        },
        {
          text: "Nombre ES",
          value: "Drug_Name_ES",
          display: "text"
        },
        {
          text: "Nombre EN",
          value: "Drug_Name_EN",
          display: "text"
        },
        {
          text: "Nombre PO",
          value: "Drug_Name_PO",
          display: "text"
        },
        {
          text: "Substrato de",
          value: "Substrate_of",
          display: "chip"
        },
        {
          text: "Induce",
          value: "Induces",
          display: "chip"
        },
        {
          text: "Inhibe",
          value: "Inhibits",
          display: "chip"
        },
        {
          text: "Relaccionado",
          value: "Related",
          display: "chip"
        },
        {
          text: "EPG",
          value: "Has_EPG",
          display: "boolean"
        },
        {
          text: "PGX",
          value: "Has_PGX",
          display: "boolean"
        },
        {
          text: "Ocultar",
          value: "Hide_In_PGX",
          display: "boolean"
        }
      ]
    };
  },
  computed: {
    elasticSearchCluster() {
      return process.env.VUE_APP_DRUGS_CLUSTER;
    },
    dataField() {
      let self = this;
      const sortFieldDisplay = this.headers.filter(
        h => h.value == self.sortField
      )[0].display;
      return (
        (sortFieldDisplay == "text" && `${self.sortField}.keyword`) ||
        self.sortField
      );
    },
    sortDirection() {
      return (this.sortAscending && "asc") || "desc";
    },
    reactiveListReact() {
      return { and: ["SearchSensor"] };
    },
    visibleTextFields() {
      return this.selectedHeaders
        .filter(h => h.display == "text")
        .map(h => h.value);
    },
    allFields() {
      return this.headers.map(h => h.text);
    },
    selectedHeaders() {
      return this.headers.filter(h => {
        return this.selectedFields.includes(h.text);
      });
    }
  },
  watch: {},
  methods: {
    updateSortingField(field) {
      this.sortField = field.value;
      this.sortAscending = true;
    },
    isSortable(fieldDisplay) {
      const sortableFields = ["text", "boolean"];
      return sortableFields.includes(fieldDisplay);
    }
  }
};
</script>
<style scoped>
.sorting-field {
  font-weight: bolder;
  color: black;
  cursor: pointer;
}

.sortable-field {
  text-decoration: underline;
  cursor: pointer;
}
</style>
